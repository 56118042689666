import Vue from 'vue'
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push( location ) {
  return originalPush.call( this, location ).catch( err => err )
}
import Index from '@/pages/home/index.vue'
import companyIndex from '@/pages/company/index.vue'
import businessIndex from '@/pages/business/index.vue'
import networkAccess from '@/pages/networkAccess/index.vue'
import industryCase from '@/pages/industryCase/index.vue'
import industryCaseDetail from '@/pages/industryCase/details.vue'
import community from '@/pages/community/index.vue'
import answerDetail from '@/pages/community/answerDetail.vue'
import articleDetail from '@/pages/community/articleDetail.vue'
import communityAsk from '@/pages/community/communityAsk.vue'
import templateLibrary from '@/pages/templateLibrary/index.vue'
import templateForm from '@/pages/templateForm/index.vue'
import homeCodeMain from '@/pages/homeCodeMain/index.vue'
import releaseLog from '@/pages/releaseLog/index.vue'
import price from '@/pages/price/index.vue'
import search from '@/pages/search/index.vue'
import aboutUs from '@/pages/aboutUs/index.vue'
import map from '@/pages/map/index.vue'
import snmsIframe from '@/pages/map/snmsIframe.vue'
import scanMap from '@/pages/scanMap/index.vue'
import yidong from '@/pages/yidong/index.vue'
import maEmote from '@/pages/maEmote/index.vue'
import createCode from '@/pages/createCode/index.vue'
import pageTemplate from '@/pages/pageTemplate/index.vue'
import identityQuery from '@/pages/identityQuery/index.vue'
import identityQueryResult from '@/pages/identityQuery/result.vue'
import websiteNavigation from '@/pages/websiteNav/websiteNavigation'
Vue.use( VueRouter )
const routes = [
  {
    path : '/',
    name : 'Index',
    component : Index
  },
  {
    path : '/pageTemplate',
    name : 'pageTemplate',
    component : pageTemplate
  },
  {
    path : '/company',
    name : 'company',
    component : companyIndex
  },
  {
    path : '/business',
    name : 'business',
    component : businessIndex
  },
  {
    path : '/networkAccess',
    name : 'networkAccess',
    component : networkAccess
  },
  {
    path : '/industryCase',
    name : 'industryCase',
    component : industryCase
  },
  {
    path : '/industryCase/details',
    name : 'industryCaseDetail',
    component : industryCaseDetail
  },

  {
    path : '/community',
    name : 'community',
    component : community
  },
  {
    path : '/community/answerDetail',
    name : 'answerDetail',
    component : answerDetail
  },
  {
    path : '/community/articleDetail',
    name : 'articleDetail',
    component : articleDetail
  },
  {
    path : '/community/communityAsk',
    name : 'communityAsk',
    component : communityAsk
  },
  {
    path : '/templateLibrary',
    name : 'templateLibrary',
    component : templateLibrary
  },
  {
    path : '/templateForm',
    name : 'templateForm',
    component : templateForm
  },
  {
    path : '/homeCodeMain',
    name : 'homeCodeMain',
    component : homeCodeMain
  },
  {
    path : '/releaseLog',
    name : 'releaseLog',
    component : releaseLog
  },
  {
    path : '/price',
    name : 'price',
    component : price
  },
  {
    path : '/search',
    name : 'search',
    component : search
  },
  {
    path : '/aboutUs',
    name : 'aboutUs',
    component : aboutUs
  },
  {
    path : '/map',
    name : 'map',
    component : map
  },
  {
    path : '/snmsIframe',
    name : 'snmsIframe',
    component : snmsIframe
  },
  {
    path : '/scanMap',
    name : 'scanMap',
    component : scanMap
  },
  {
    path : '/yidong',
    name : 'yidong',
    component : yidong
  },
  {
    path : '/maEmote',
    name : 'maEmote',
    component : maEmote
  },
  {
    path : '/createCode',
    name : 'createCode',
    component : createCode
  },
  {
    path : '/identityQuery',
    name : 'identityQuery',
    component : identityQuery
  },
  {
    path : '/identityQuery/result',
    name : 'identityQueryResult',
    component : identityQueryResult
  },
  {
    path : '/websiteNavigation',
    name : 'websiteNavigation',
    component : websiteNavigation
  }
]
export function createRouter() {
  return new VueRouter( {
    mode : 'history',
    routes
  } )
}

// '/business',
// '/company',

import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export function getToken() {
  return Cookies.get( TokenKey )
}

export function getNickName() {
  return Cookies.get( 'nickName' )
}

export function setToken( token ) {
  return Cookies.set( TokenKey, token )
}

export function removeToken() {
  return Cookies.remove( TokenKey )
}
export function setNickName( name ) {
  return Cookies.set( 'nickName', name )
}

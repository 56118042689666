import {
  login,
  getInfo,
  checkSceneId
} from '@/api/login'
import {
  getToken,
  setToken,
  removeToken,
  setNickName, getNickName
} from '@/utils/auth'

// const state = () => {
//   return{
//     token: getToken(),
//     name: '',
//     userName: '',
//     qrList: [],
//   }
// }

// const mutations = {
//   SET_TOKEN: (state, token) => {
//     state.token = token
//   },
//   SET_NAME: (state, name) => {
//     state.name = name
//   },
//   SET_USERNAME: (state, userName) => {
//     state.userName = userName
//   },
//   SET_QRLIST: (state, qrList) => {
//     console.log('23424',qrList)
//     state.qrList = qrList
//   },
// }

// const actions = {
//   setToken( { commit }, token ) {
//     commit( 'SET_TOKEN', token )
//   },
//   setNickName( { commit }, name ) {
//     console.log('????',name)
//     commit( 'SET_NAME', name )
//   },
//    // 保存生码数据10条
//   saveQrList({ commit }, list) {
//     commit('SET_QRLIST', list)
//   },
//   // 官网登录静默
//   LoginWeb({ commit }, userInfo) {
//     const username = userInfo.username.trim()
//     const password = userInfo.password
//     const code = userInfo.code
//     const uuid = userInfo.uuid
//     return new Promise((resolve, reject) => {
//       login(username, password, code, uuid).then(res => {
//         setToken(res.token)
//         commit('SET_TOKEN', res.token)
//         resolve()
//       }).catch(error => {
//         reject(error)
//       })
//     })
//   },
//   // 获取用户信息
//   GetInfo({ commit, state }) {
//     return new Promise((resolve, reject) => {
//       getInfo().then(res => {
//         const user = res.user
//         setNickName(user.nickName)
//         commit('SET_NAME', user.nickName)
//         commit('SET_USERNAME', user.userName)
//         resolve(res)
//       }).catch(error => {
//         reject(error)
//       })
//     })
//   },

//   // 前端 登出
//   FedLogOut({ commit }) {
//     return new Promise(resolve => {
//       commit('SET_TOKEN', '')
//       setNickName('')
//       removeToken()
//       resolve()
//     })
//   },

//   CheckSceneId({ commit },sceneId) {
//     return new Promise((resolve, reject) => {
//       checkSceneId(sceneId).then(res => {
//         if(res.code == 200){
//           if(res.flag == 1){
//             setToken(res.token)
//             commit('SET_TOKEN', res.token)
//             resolve(res)
//           }
//         }
//       }).catch(error => {
//         reject(error)
//       })
//     })
//   }
// }
// export default {
//   state,
//   actions,
//   mutations
// }
const user = {
  state : {
    token : getToken(),
    name : getNickName(),
    userName : '',
    qrList : []
  },
  mutations : {
    SET_TOKEN : ( state, token ) => {
      state.token = token
    },
    SET_NAME : ( state, name ) => {
      state.name = name
    },
    SET_USERNAME : ( state, userName ) => {
      state.userName = userName
    },
    SET_QRLIST : ( state, qrList ) => {
      state.qrList = qrList
    }
  },
  actions : {
    setToken( {
      commit
    }, token ) {
      commit( 'SET_TOKEN', token )
    },
    setNickName( {
      commit
    }, name ) {
      commit( 'SET_NAME', name )
    },
    // 保存生码数据10条
    saveQrList( {
      commit
    }, list ) {
      commit( 'SET_QRLIST', list )
    },
    // 官网登录静默
    LoginWeb( {
      commit
    }, userInfo ) {
      const username = userInfo.username.trim()
      const password = userInfo.password
      const code = userInfo.code
      const uuid = userInfo.uuid
      const captchaCode = userInfo.captchaCode
      return new Promise( ( resolve, reject ) => {
        login( username, password, code, uuid, captchaCode ).then( res => {
          setToken( res.token )
          commit( 'SET_TOKEN', res.token )
          resolve()
        } ).catch( error => {
          reject( error )
        } )
      } )
    },
    // 获取用户信息
    GetInfo( {
      commit,
      state
    } ) {
      return new Promise( ( resolve, reject ) => {
        getInfo().then( res => {
          const user = res.user
          setNickName( user.nickName )
          commit( 'SET_NAME', user.nickName )
          commit( 'SET_USERNAME', user.userName )
          resolve( res )
        } ).catch( error => {
          reject( error )
        } )
      } )
    },

    // 前端 登出
    FedLogOut( {
      commit
    } ) {
      return new Promise( resolve => {
        commit( 'SET_TOKEN', '' )
        setNickName( '' )
        removeToken()
        resolve()
      } )
    },

    CheckSceneId( {
      commit
    }, sceneId ) {
      return new Promise( ( resolve, reject ) => {
        checkSceneId( sceneId ).then( res => {
          if ( res.code == 200 ) {
            if ( res.flag == 1 ) {
              setToken( res.token )
              commit( 'SET_TOKEN', res.token )
              resolve( res )
            }
          }
        } ).catch( error => {
          reject( error )
        } )
      } )
    }
  }
}
export default user

// import user from './modules/user'

// export const mutations = {

// }
// export const actions = {
//   async nuxtServerInit( { commit, dispatch }, payload ) {
//     const { req } = payload
//     if ( req.headers.cookie ) {
//       const parse = cookieparser.parse( req.headers.cookie )
//       const token = parse['Admin-Token']
//       const name = parse['nickName']
//       dispatch( 'setToken', token )
//       dispatch( 'setNickName' ,name )
//     }
//   }
// }

// export const modules = {
//   user
// }

import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import getters from './getters'
const cookieparser = require( 'cookieparser' )

Vue.use( Vuex )

export default () => new Vuex.Store( {
  mutations : {
    setDragElement( state, dragElement ) {
      state.dragElement = dragElement
    }

  },
  actions : {
    nuxtServerInit( {
      commit,
      dispatch
    }, payload ) {
      const {
        req
      } = payload
      if ( req.headers.cookie ) {
        const parse = cookieparser.parse( req.headers.cookie )
        const token = parse['Admin-Token']
        const name = parse['nickName'] && parse['nickName'] != undefined ? parse['nickName'] : ''
        dispatch( 'setToken', token )
        dispatch( 'setNickName', name )
      }
    }
  },
  modules : {
    user
  },
  getters
} )
